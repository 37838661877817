import React from "react"
import { Layout } from "../components/Layout/Layout"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout>
      <SEO title="404" />
      <h1>404 - taka strona nie istnieje</h1>
      <p>Przepraszamy, szukana stronie nie istnieje</p>
    </Layout>
  )
}
